<template>
<div>
  <div bgcolor="#f4f4f4" width="100%" style="margin:0;padding:0" class="__web-inspector-hide-shortcut__">
    <div class="adM">
    </div>
    <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation" bgcolor="#f4f4f4">
        <tbody>
          <tr>
              <td class="m_5327504558924647655m_hide" style="font-size:0;line-height:1px;height:1px" height="1">&nbsp;</td>
              <td class="m_5327504558924647655m_wrapper" bgcolor="#2962ff" style="width:580px">
                <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation">
                    <tbody>
                      <tr>
                          <td style="padding-top:15px;padding-right:15px;padding-bottom:15px;padding-left:30px">
                            <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation" style="text-align:center">
                                <tbody>
                                  <tr style="font-size:0">
                                      <th class="m_5327504558924647655columns m_5327504558924647655full" valign="middle" bgcolor="transparent" style="text-align:left;word-wrap:break-word;word-break:normal;display:inline-block;min-width:214px;max-width:100%;width:40%;min-width:-webkit-calc(40%);min-width:calc(40%);width:-webkit-calc(230400px - 48000%);width:calc(230400px - 48000%)">
                                        <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100%">
                                            <tbody>
                                              <tr class="logo">
                                                  <td align="left"><a href="#" rel="noopener noreferrer" style="text-decoration:none" target="_blank" data-saferedirecturl=""><img id="m_5327504558924647655OWATemporaryImageDivContainer1" src="" alt="" border="0" width="174" style="max-width:80px;display:block;width:174px;float:left;border-radius: 50%;" class="CToWUd"></a></td>
                                              </tr>
                                            </tbody>
                                        </table>
                                      </th>
                                      <th class="m_5327504558924647655columns m_5327504558924647655full" valign="middle" style="text-align:center;display:inline-block;min-width:321px;max-width:100%;width:60%;min-width:-webkit-calc(60%);min-width:calc(60%);width:-webkit-calc(230400px - 48000%);width:calc(230400px - 48000%)">
                                        <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation">
                                            <tbody>
                                              <tr>
                                                  <td bgcolor="transparent" style="word-wrap:break-word;word-break:normal;width:100%;padding-top:0px;padding-right:30px;padding-bottom:0px;padding-left:0px">
                                                    <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100%">
                                                        <tbody>
                                                          <tr>
                                                          </tr>
                                                        </tbody>
                                                    </table>
                                                  </td>
                                              </tr>
                                            </tbody>
                                        </table>
                                      </th>
                                  </tr>
                                </tbody>
                            </table>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </td>
              <td class="m_5327504558924647655m_hide" style="font-size:0;line-height:1px;height:1px" height="1">&nbsp;</td>
          </tr>
        </tbody>
    </table>
    <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation" bgcolor="#f4f4f4">
        <tbody>
          <tr>
              <td class="m_5327504558924647655m_hide" style="font-size:0;line-height:1px;height:1px" height="1">&nbsp;</td>
              <td class="m_5327504558924647655m_wrapper" bgcolor="#ffffff" style="width:580px">
                <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation">
                    <tbody>
                      <tr>
                          <td valign="top" style="padding-top:35px;padding-right:0px;padding-left:0px;text-align:left">
                            <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation">
                                <tbody>
                                  <tr>
                                      <td bgcolor="transparent" style="word-wrap:break-word;word-break:normal;padding-top:0px;padding-right:56px;padding-bottom:0px;padding-left:56px">
                                        <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100%" style="display:table">
                                            <tbody>
                                              <tr>
                                                  <td style="font-family:Arial;font-size:16px;color:#4d5592;font-weight:normal;word-wrap:break-word;word-break:normal;font-style:normal;line-height:24px;padding-bottom:0px;width:100%;text-align:left">
                                                    <h1 style="font-family:Arial;font-size:36px;color:#00185e;word-wrap:break-word;word-break:normal;font-style:normal;line-height:45px;font-weight:normal">Réservation n° {{ orderRef }}</h1>
                                                  </td>
                                              </tr>
                                            </tbody>
                                        </table>
                                      </td>
                                  </tr>
                                </tbody>
                            </table>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </td>
              <td class="m_5327504558924647655m_hide" style="font-size:0;line-height:1px;height:1px" height="1">&nbsp;</td>
          </tr>
        </tbody>
    </table>
    <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation" bgcolor="#f4f4f4">
        <tbody>
          <tr>
              <td class="m_5327504558924647655m_hide" style="font-size:0;line-height:1px;height:1px" height="1">&nbsp;</td>
              <td class="m_5327504558924647655m_wrapper" bgcolor="#ffffff" style="width:580px">
                <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation">
                    <tbody>
                      <tr>
                          <td valign="top" style="padding-top:0px;padding-right:56px;padding-bottom:20px;padding-left:56px;text-align:left">
                            <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation">
                                <tbody>
                                  <tr>
                                      <td bgcolor="transparent" style="word-wrap:break-word;word-break:normal">
                                        <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100%" style="display:table">
                                            <tbody>
                                              <tr>
                                                  <td style="font-family:Arial;font-size:16px;color:#4d5592;font-weight:normal;word-wrap:break-word;word-break:normal;font-style:normal;line-height:24px;padding-bottom:0px;width:100%;text-align:left">
                                                    <p style="font-family:Arial;font-size:16px;color:#4d5592;font-weight:normal;word-wrap:break-word;word-break:normal;font-style:normal;line-height:24px;padding-bottom:0px">Bonjour,</p>
                                                    <p style="font-family:Arial;font-size:16px;color:#4d5592;font-weight:normal;word-wrap:break-word;word-break:normal;font-style:normal;line-height:24px;padding-bottom:0px">Votre réservation a bien été prise en compte et nous vous remercions de votre confiance.</p>
                                                    <p style="font-family:Arial;font-size:16px;color:#4d5592;font-weight:normal;word-wrap:break-word;word-break:normal;font-style:normal;line-height:24px;padding-bottom:0px">Vous recevrez un email récapitulatifs de votre réservation. En règle générale, vous recevrez un email de confirmation dans les 15 minutes suivant votre réservation. </p>
                                                  </td>
                                              </tr>

                                              <tr>
                                                <button @click="orderCanceled()">orderCanceled</button>
                                              </tr>


                                            </tbody>
                                        </table>
                                      </td>
                                  </tr>
                                </tbody>
                            </table>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </td>
              <td class="m_5327504558924647655m_hide" style="font-size:0;line-height:1px;height:1px" height="1">&nbsp;</td>
          </tr>
        </tbody>
    </table>
    <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation" bgcolor="#f4f4f4">
        <tbody>
          <tr>
              <td class="m_5327504558924647655m_hide" style="font-size:0;line-height:1px;height:1px" height="1">&nbsp;</td>
              <td class="m_5327504558924647655m_wrapper" bgcolor="#ffffff" style="width:580px">
                <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation">
                    <tbody>
                      <tr>
                          <td valign="top" style="padding-top:0px;padding-right:92px;padding-bottom:0px;padding-left:92px;text-align:left">
                            <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation">
                                <tbody>
                                  <tr>
                                      <td bgcolor="#f3f8f9" style="word-wrap:break-word;word-break:normal;width:100%;padding:26px">
                                        <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100%" style="display:table">
                                            <tbody>
                                              <tr>
                                                  <td style="font-family:Arial;font-size:16px;color:#4d5592;font-weight:normal;word-wrap:break-word;word-break:normal;font-style:normal;line-height:24px;padding-bottom:0px;width:100%;text-align:left">
                                                    <p style="font-family:Arial;font-size:16px;color:#4d5592;font-weight:normal;word-wrap:break-word;word-break:normal;font-style:normal;line-height:24px;padding-bottom:15px">
                                                      Nous vous remercions pour votre réservation
                                                    </p>
                                                  </td>
                                              </tr>
                                            </tbody>
                                        </table>
                                        <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100%">
                                            <tbody>
                                              <tr>
                                                  <td align="center">
                                                    <table cellpadding="0" cellspacing="0" border="0" role="presentation" class="m_5327504558924647655btn_table" style="display:table">
                                                        <tbody>
                                                          <tr>
                                                              <td class="m_5327504558924647655btn_td" bgcolor="#2962ff" style="font-family:Arial;font-size:18px;color:#ffffff;padding-top:16px;padding-right:12px;padding-bottom:16px;padding-left:12px;text-align:center;text-decoration:none;border-radius:6px;text-overflow:ellipsis;overflow:hidden;word-wrap:break-word;word-break:normal;font-weight:bold;font-style:normal;line-height:17px;border-top-width:12px;display:inline-block">
                                                                <router-link :to="{ name: 'Customer-welcomepage'}" rel="noopener noreferrer" style="color:#ffffff;text-decoration:none">
                                                                  Retour vers la page d'accueil
                                                                </router-link>
                                                              </td>
                                                          </tr>
                                                        </tbody>
                                                    </table>
                                                  </td>
                                              </tr>
                                            </tbody>
                                        </table>
                                      </td>
                                  </tr>
                                </tbody>
                            </table>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </td>
              <td class="m_5327504558924647655m_hide" style="font-size:0;line-height:1px;height:1px" height="1">&nbsp;</td>
          </tr>
        </tbody>
    </table>
    <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation" bgcolor="#f4f4f4">
        <tbody>
          <tr>
              <td class="m_5327504558924647655m_hide" style="font-size:0;line-height:1px;height:1px" height="1">&nbsp;</td>
              <td class="m_5327504558924647655m_wrapper" bgcolor="#ffffff" style="width:580px">
                <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation">
                    <tbody>
                      <tr>
                          <td valign="top" style="padding-top:20px;padding-right:0px;padding-bottom:20px;padding-left:0px;text-align:left">
                            <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation">
                                <tbody>
                                  <tr>
                                      <td bgcolor="transparent" style="word-wrap:break-word;word-break:normal;padding-top:0px;padding-right:56px;padding-bottom:0px;padding-left:56px">
                                        <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100%" style="display:table">
                                            <tbody>
                                              <tr>
                                                  <td style="font-family:Arial;font-size:16px;color:#4d5592;font-weight:normal;word-wrap:break-word;word-break:normal;font-style:normal;line-height:24px;padding-bottom:0px;width:100%;text-align:left">
                                                    <p style="font-family:Arial;font-size:16px;color:#4d5592;font-weight:normal;word-wrap:break-word;word-break:normal;font-style:normal;line-height:24px;padding-bottom:0px"></p>
                                                    <!-- <p style="font-family:Arial;font-size:16px;color:#4d5592;font-weight:normal;word-wrap:break-word;word-break:normal;font-style:normal;line-height:24px;padding-bottom:30px">L'équipe ****<br></p> -->
                                                  </td>
                                              </tr>
                                            </tbody>
                                        </table>
                                      </td>
                                  </tr>
                                </tbody>
                            </table>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </td>
              <td class="m_5327504558924647655m_hide" style="font-size:0;line-height:1px;height:1px" height="1">&nbsp;</td>
          </tr>
        </tbody>
    </table>
    <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation" bgcolor="#f4f4f4">
        <tbody>
          <tr>
              <td class="m_5327504558924647655m_hide" style="font-size:0;line-height:1px;height:1px" height="1">&nbsp;</td>
              <td class="m_5327504558924647655m_wrapper" bgcolor="transparent" valign="top" style="width:580px;text-align:left;word-wrap:break-word;word-break:normal">
                <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation">
                    <tbody>
                      <tr>
                          <td height="30" style="line-height:30px;height:30px"><img alt="" src="https://ci6.googleusercontent.com/proxy/0MiK2GUnFDGdhMPf6Pv9BN3ct8Dl8NMQcHX1k-1NRPYYhmJ43P8FQxFJ7GHnqS9ecnW6kU0F6bJ6vh-0SUh9LJHTgb25B6eEii0RbvV00_8bSgDjTgk=s0-d-e1-ft#https://ovh.slgnt.eu/Portal/ResourceHandler/static/x.png?no-cache=1" border="0" height="30" style="display:block;height:30px" class="CToWUd"></td>
                      </tr>
                    </tbody>
                </table>
              </td>
              <td class="m_5327504558924647655m_hide" style="font-size:0;line-height:1px;height:1px" height="1">&nbsp;</td>
          </tr>
        </tbody>
    </table>
    <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation" bgcolor="#f4f4f4">
        <tbody>
          <tr>
              <td class="m_5327504558924647655m_hide" style="font-size:0;line-height:1px;height:1px" height="1">&nbsp;</td>
              <td class="m_5327504558924647655m_wrapper" bgcolor="#f4f4f4" style="width:580px">
              </td>
              <td class="m_5327504558924647655m_hide" style="font-size:0;line-height:1px;height:1px" height="1">&nbsp;</td>
          </tr>
        </tbody>
    </table>
    <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation" bgcolor="#f4f4f4">
        <tbody>
          <tr>
              <td class="m_5327504558924647655m_hide" style="font-size:0;line-height:1px;height:1px" height="1">&nbsp;</td>
              <td class="m_5327504558924647655m_wrapper" bgcolor="transparent" valign="top" style="width:580px;text-align:left;word-wrap:break-word;word-break:normal">
                <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation">
                    <tbody>
                      <tr>
                          <td height="20" style="line-height:20px;height:20px"><img alt="" src="https://ci6.googleusercontent.com/proxy/0MiK2GUnFDGdhMPf6Pv9BN3ct8Dl8NMQcHX1k-1NRPYYhmJ43P8FQxFJ7GHnqS9ecnW6kU0F6bJ6vh-0SUh9LJHTgb25B6eEii0RbvV00_8bSgDjTgk=s0-d-e1-ft#https://ovh.slgnt.eu/Portal/ResourceHandler/static/x.png?no-cache=1" border="0" height="20" style="display:block;height:20px" class="CToWUd"></td>
                      </tr>
                    </tbody>
                </table>
                <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100%" style="display:table">
                    <tbody>
                      <tr>
                          <td style="font-family:Arial;font-size:16px;color:#4d5592;font-weight:normal;word-wrap:break-word;word-break:normal;font-style:normal;line-height:24px;padding-bottom:0px;width:100%;text-align:center">
                            <p style="font-family:Arial;font-size:16px;color:#4d5592;font-weight:normal;word-wrap:break-word;word-break:normal;font-style:normal;line-height:24px;padding-bottom:0px;text-align:center"><span style="font-size:12px">© 2021 LOKMI</span></p>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </td>
              <td class="m_5327504558924647655m_hide" style="font-size:0;line-height:1px;height:1px" height="1">&nbsp;</td>
          </tr>
        </tbody>
    </table>
  </div>
</div>
</template>

<script>

import axios from '@/axios.js'
import { loadStripe } from '@stripe/stripe-js';

export default {

  data () {
    return {
      orderRef: null,
      stripe: {},
    }
  },
  components: {
  },
  computed: {
    stripeKey(){
        return this.$store.state.moduleSetting.customerSetting ? this.$store.state.moduleSetting.customerSetting.STRIPE_KEY : ''
    },
  },
  async mounted() {
      console.log('stripeKey', this.stripeKey)
      this.stripe = await loadStripe(this.stripeKey);
  },
  methods: {
    checkRef() {
      this.orderRef = this.$route.query.ref
    },
    orderCanceled() {

        this.$vs.loading()

        const payload = {
          orderRef: this.orderRef
        }

        var _this = this;

        this.axios.post('/api/v4/paiement/stripe/repeat-checkout', payload)
        .then(function (res) {
          _this.$vs.loading.close()

          // When the customer clicks on the button, redirect them to Checkout.
          const result = _this.stripe.redirectToCheckout({
            sessionId: res.data,
          });

          if (result.error) {
            _this.$vs.loading.close()
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            this.$vs.notify({
              time: 70000,
              title:'Alert',
              text:result.error.message,
              color:'danger',
              iconPack: 'feather',
              icon:'icon-alert-circle',
              position:'top-right'
            })
          }
        })
        .catch(function (error) {
          console.log('error', error);
          this.$vs.notify({
            time: 70000,
            title:'Alert',
            text:'Cette action ne peut pas être réalisée.',
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle',
            position:'top-right'
          })
        });
    }
  },
  created() {
      console.log('this.$route.query', this.$route.query)
      this.checkRef();
  }
}

</script>

<style lang="scss" scoped>

</style>

